
    /deep/.el-button--default:hover{
        border-color: #FD4446;
        color: #FD4446;
        background: #ffffff;
    }
    .isShowActive{
        background: #FFECC8!important;
        border-color: #ffffff!important;
    }
    /deep/.is-checked .el-radio__inner{
        border-color: #FD4446!important;
        background: #FD4446!important;
    }
    /deep/.el-radio__inner{
        width: 20px;
        height: 20px;
    }
    /deep/.is-checked .el-radio__label{
        color: #333333!important;
        font-size: 18px;
    }
    /deep/.el-radio .el-radio__label{
        color: #1E63F1;
        font-size: 18px;
    }
    /deep/.el-form-item__label{
        color: #333333;
        font-size: 18px;
        font-width: 500;
    }
    /deep/.radio3{
        .el-radio__label{
            color: #333333;
        }
    }
    .PlanStepTwo{
        height: calc(100vh - 200px);
        padding: 20px 30px;
        .PlanStepTwoHeader{
            color: #333333;
            font-size: 28px;
        }
        .PlanStepTwoForm{
            margin-top: 70px;
            padding-left: 40px;
        }
        .nextBackBtn{
            padding-left: 136px;
            padding-top: 20px;
        }
    }
    .CustomBudgetBox{
        display: flex;
        flex-direction: column;
        padding: 40px 60px;
        span{
            color: #989898;
            font-size: 18px;
            line-height: 1;
            margin-top: 20px;
        }
        .title{
            color: #333333;
            font-size: 24px;
            padding-bottom: 20px;
        }
        span:nth-child(4){
            line-height: 27px;
        }
        .box-bottom{
            margin-top: 30px;
            font-size: 18px;
            color: #333333;
        }
    }
    .CustomBudget-radio{
        margin-top: 30px;
        font-size: 18px;
        color: #333333;
    }
    .hoverWrapper {
        padding: 10px 10px 10px 20px;
        z-index: 3302;
        position: fixed;
        top: 10px;
        left: 20px;
        width: 210px;
        /*height: 200px;*/
        background-color: #FFF;
        border: 1px solid #EEE;
        font-size: 18px;
        p{
            line-height: 27px;
        }
    }
    .clickWrapper{
        z-index: 3302;
        position: fixed;
        top: 10px;
        left: 20px;
        width: 246px;
        padding-top: 20px;
        background-color: #FFF;
        border: 1px solid #EEE;
        padding-left: 20px;
        font-size: 18px;
        padding-bottom: 30px;
        /deep/.el-radio{
            margin-top: 20px;
        }
        /deep/.el-radio-group{
            display: flex;
            flex-direction: column;
        }
        /deep/.el-radio__label{
            color: #333333!important;
            font-size: 18px;
        }
        .customInput{
            margin-top: 10px;
            display: flex;
            margin-left: 30px;
            padding-right: 34px;
            align-items: center;
            span{
                font-size: 18px;
            }
        }
        .clickWrapperBtn{
            margin-top: 20px;
            padding-left: 44px;
        }
    }


